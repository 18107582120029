@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,500&display=swap");

body {
  background-color: #fff !important;
  font-family: "Roboto", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .ui.form input[type="text"] {
  border-radius: 8px !important;
  border: none !important;
}

.ui.form input[type="password"] {
  border-radius: 8px !important;
  border: none !important;
} */

.login-bg {
  background-image: url("/69.jpg");
  background: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.ui.menu .item {
  font-weight: 500 !important;
}

.ui.secondary.pointing.menu .active.item {
  border-color: #fbbd08 !important;
}

.ui.secondary.pointing.menu .item {
  border-bottom-width: 2px !important;
}
.ui.segment {
  border-radius: 8px !important;
}

.dangercolor {
  background-color: #ffebeb !important;
}

.mt20 {
  margin-top: 1.2rem !important;
}

.pt14 {
  padding-top: 0.9rem;
}

.ui.items > .item > .content > .header {
  font-weight: 500 !important;
}

.label-white {
  color: #fff;
}
