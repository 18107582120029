.topmargin {
  margin-top: 2rem;
}

.hastagblue {
  color: #1778c2 !important;
}

.ml {
  margin-left: 10px !important;
}

.pointer {
  cursor: pointer;
}

.topmargin {
  margin-top: 1rem;
}

.hastagblue {
  color: #1778c2 !important;
  font-size: 11px !important;
}

.togglefloat {
  float: right;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-around;
  width: 200px;
  margin-top: 10px;
}

.notificationdesc {
  font-size: 15px;
  text-align: center;
  font-weight: 200;
  line-height: 3;
}

.ml {
  margin-left: 10px;
}
.textcenter {
  text-align: center;
}

.topbottompadding {
  margin-top: 15px !important;
  padding: 5px 10px !important;
}
.storytitle {
  font-size: 1.2rem !important;
}

.publishedtext {
  font-size: 1rem !important;
  font-weight: bold;
}

.btnflex {
  display: flex;
  justify-content: space-around;
}
