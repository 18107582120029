/* body {
  margin: 0;
  background-color: #f9f9f9 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loginbg {
  background: #eeeeee;
}

.mxauto {
  margin: auto;
}

.dflex {
  display: flex;
}

.pointer {
  cursor: pointer;
}

.circular {
  border: 1px solid #eee;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
}

.ml {
  margin-left: 20px !important;
}

.navbarpadding {
  padding: 5px 0 !important;
}

.textcenter {
  text-align: center;
}
.red {
  color: red;
}

.justify-content-between {
  justify-content: space-between;
}

.wrap {
  flex-wrap: wrap;
}

.radius {
  border-radius: 50px !important;
}

.radius:hover {
  -webkit-box-shadow: 2px 4px 19px 0px rgba(0, 0, 0, 0.35) !important;
  -moz-box-shadow: 2px 4px 19px 0px rgba(0, 0, 0, 0.35) !important;
  box-shadow: 2px 4px 19px 0px rgba(0, 0, 0, 0.35) !important;
}
