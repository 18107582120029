@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,500&display=swap);
/* body {
  margin: 0;
  background-color: #f9f9f9 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loginbg {
  background: #eeeeee;
}

.mxauto {
  margin: auto;
}

.dflex {
  display: flex;
}

.pointer {
  cursor: pointer;
}

.circular {
  border: 1px solid #eee;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
}

.ml {
  margin-left: 20px !important;
}

.navbarpadding {
  padding: 5px 0 !important;
}

.textcenter {
  text-align: center;
}
.red {
  color: red;
}

.justify-content-between {
  justify-content: space-between;
}

.wrap {
  flex-wrap: wrap;
}

.radius {
  border-radius: 50px !important;
}

.radius:hover {
  box-shadow: 2px 4px 19px 0px rgba(0, 0, 0, 0.35) !important;
}

body {
  background-color: #fff !important;
  font-family: "Roboto", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .ui.form input[type="text"] {
  border-radius: 8px !important;
  border: none !important;
}

.ui.form input[type="password"] {
  border-radius: 8px !important;
  border: none !important;
} */

.login-bg {
  background-image: url("/69.jpg");
  background: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.ui.menu .item {
  font-weight: 500 !important;
}

.ui.secondary.pointing.menu .active.item {
  border-color: #fbbd08 !important;
}

.ui.secondary.pointing.menu .item {
  border-bottom-width: 2px !important;
}
.ui.segment {
  border-radius: 8px !important;
}

.dangercolor {
  background-color: #ffebeb !important;
}

.mt20 {
  margin-top: 1.2rem !important;
}

.pt14 {
  padding-top: 0.9rem;
}

.ui.items > .item > .content > .header {
  font-weight: 500 !important;
}

.label-white {
  color: #fff;
}

.public_topmargin__8fhZQ {
  margin-top: 2rem;
}

.public_hastagblue__2BNAd {
  color: #1778c2 !important;
}

.public_ml__3Gkj_ {
  margin-left: 10px !important;
}

.public_pointer__yTFV_ {
  cursor: pointer;
}

.public_topmargin__8fhZQ {
  margin-top: 1rem;
}

.public_hastagblue__2BNAd {
  color: #1778c2 !important;
  font-size: 11px !important;
}

.public_togglefloat__2MPY- {
  float: right;
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: space-around;
  width: 200px;
  margin-top: 10px;
}

.public_notificationdesc__1RFlX {
  font-size: 15px;
  text-align: center;
  font-weight: 200;
  line-height: 3;
}

.public_ml__3Gkj_ {
  margin-left: 10px;
}
.public_textcenter__uTgWt {
  text-align: center;
}

.public_topbottompadding__3vY1f {
  margin-top: 15px !important;
  padding: 5px 10px !important;
}
.public_storytitle__1dXB- {
  font-size: 1.2rem !important;
}

.public_publishedtext__2ZMmy {
  font-size: 1rem !important;
  font-weight: bold;
}

.public_btnflex__1_XMU {
  display: flex;
  justify-content: space-around;
}

.button_addicon__3mnHN {
  display: flex;
  margin-top: 5px;
}

.button_pointer__2sorX {
  cursor: pointer;
}

.button_textcenter__1PUFH {
  text-align: center;
}

.button_inputwidth__2bAVD {
  width: 80% !important;
}

.button_formpadding__3QY_8 {
  padding: 0 100px;
}

.button_dflexcenter__1Jf5x {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.verified_flexstart__1LPNO {
  flex-wrap: wrap;
  width: 250px;
  height: 250px;
  background-color: #425461;
  margin-right: 14px;
  margin-bottom: 14px;

  border-radius: 5px;
  box-shadow: inset;
  padding: 20px;
  border: 1px solid #242d33;
}

.verified_title__1bNVi {
  font-size: 1.4rem;
  color: #ffffff;
}

.verified_citytext__QMmN6 {
  font-size: 1.2rem;
  color: #fff;
}

.verified_number__2YC6- {
  font-size: 2rem;
  color: #fff;
}
.verified_approvedtext__Yb9G3 {
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
}

.delete_topmargin__2gM2O {
  margin-top: 2rem;
}

.delete_hastagblue__T55wH {
  color: #1778c2 !important;
}

.delete_togglefloat__3N1ko {
  float: right;
  display: flex;
  align-items: center;
  justify-content: space-around;

  width: 200px;
}

.delete_ml__2IL5Q {
  margin-left: 10px !important;
}

.delete_pointer__wdcqE {
  cursor: pointer;
}

.delete_topmargin__2gM2O {
  margin-top: 1rem;
}

.delete_hastagblue__T55wH {
  color: #1778c2 !important;
  font-size: 11px !important;
}

.delete_togglefloat__3N1ko {
  float: right;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 200px;
  margin-top: 10px;
}

.delete_notificationdesc__1tkYR {
  font-size: 15px;
  text-align: center;
  font-weight: 200;
  line-height: 3;
}

.delete_ml__2IL5Q {
  margin-left: 10px;
}
.delete_textcenter__3F8Mw {
  text-align: center;
}

.delete_topbottompadding__3kkl4 {
  margin-top: 15px !important;
  padding: 5px 10px !important;
}
.delete_storytitle__2Y7nX {
  font-size: 12px !important;
}

.delete_publishedtext__3JUJe {
  font-size: 11px !important;
}

.delete_btnflex__ARDNs {
  display: flex;
  justify-content: space-around;
}

.user_topmargin__I6hfI {
  margin-top: 1rem;
}

.user_hastagblue__1PF9s {
  color: #1778c2 !important;
  font-size: 11px !important;
}

.user_togglefloat__3H2u8 {
  float: right;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 200px;
  margin-top: 10px;
}

.user_notificationdesc__DNn-m {
  font-size: 15px;
  text-align: center;
  font-weight: 200;
  line-height: 3;
}

.user_ml__2WjPr {
  margin-left: 10px;
}
.user_textcenter__3iC8o {
  text-align: center;
}

.user_topbottompadding__253SF {
  padding: 5px 5px !important;
}
.user_storytitle__15PYF {
  font-size: 12px !important;
}

.user_publishedtext__1lYc0 {
  font-size: 11px !important;
}

.user_btnflex__1wl91 {
  display: flex;
  justify-content: space-around;
}

.user_topmargin__hme4V {
  margin-top: 1rem;
}

.user_hastagblue__2p8xk {
  color: #1778c2 !important;
  font-size: 11px !important;
}

.user_togglefloat__OAHL3 {
  float: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 200px;
  margin-top: 10px;
}

.user_notificationdesc__3ExQj {
  font-size: 15px;
  text-align: center;
  font-weight: 200;
  line-height: 3;
}

.user_ml__3GAV1 {
  margin-left: 10px;
}
.user_textcenter__2GrQm {
  text-align: center;
}

.user_topbottompadding__133cX {
  padding: 5px 5px !important;
}
.user_storytitle__QxaqY {
  padding-top: 0.9rem;
  font-size: 1.2rem !important;
}

.user_publishedtext__2Mgca {
  font-size: 1rem !important;
  font-weight: bold;
}

.user_btnflex__2WiVt {
  display: flex;
  justify-content: space-around;
}

.user_topmargin__1eTc1 {
  margin-top: 1rem;
}

.user_hastagblue__3l6Ve {
  color: #1778c2 !important;
  font-size: 11px !important;
}

.user_togglefloat__Rwdxu {
  float: right;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 200px;
  margin-top: 10px;
}

.user_notificationdesc__3-NOV {
  font-size: 15px;
  text-align: center;
  font-weight: 200;
  line-height: 3;
}

.user_ml__1-dn_ {
  margin-left: 10px;
}
.user_textcenter__2za-Q {
  text-align: center;
}

.user_topbottompadding__35QZ_ {
  padding: 5px 5px !important;
}
.user_storytitle__3OIFq {
  font-size: 1.3rem !important;
  margin-top: 10px;
  font-weight: 700;
}

.user_publishedtext__13oQD {
  font-size: 1rem;
  color: #6a6a6a;
}

.user_btnflex__3TJh3 {
  display: flex;
  justify-content: space-around;
}

