.flexstart {
  flex-wrap: wrap;
  width: 250px;
  height: 250px;
  background-color: #425461;
  margin-right: 14px;
  margin-bottom: 14px;

  border-radius: 5px;
  box-shadow: inset;
  padding: 20px;
  border: 1px solid #242d33;
}

.title {
  font-size: 1.4rem;
  color: #ffffff;
}

.citytext {
  font-size: 1.2rem;
  color: #fff;
}

.number {
  font-size: 2rem;
  color: #fff;
}
.approvedtext {
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
}
