.topmargin {
  margin-top: 1rem;
}

.hastagblue {
  color: #1778c2 !important;
  font-size: 11px !important;
}

.togglefloat {
  float: right;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 200px;
  margin-top: 10px;
}

.notificationdesc {
  font-size: 15px;
  text-align: center;
  font-weight: 200;
  line-height: 3;
}

.ml {
  margin-left: 10px;
}
.textcenter {
  text-align: center;
}

.topbottompadding {
  padding: 5px 5px !important;
}
.storytitle {
  font-size: 1.3rem !important;
  margin-top: 10px;
  font-weight: 700;
}

.publishedtext {
  font-size: 1rem;
  color: #6a6a6a;
}

.btnflex {
  display: flex;
  justify-content: space-around;
}
