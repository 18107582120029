.addicon {
  display: flex;
  margin-top: 5px;
}

.pointer {
  cursor: pointer;
}

.textcenter {
  text-align: center;
}

.inputwidth {
  width: 80% !important;
}

.formpadding {
  padding: 0 100px;
}

.dflexcenter {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
